import React, { useEffect, useState } from "react";
import GSwapContract from "./contracts/Token.json";
import Disconnected from "./containers/Disconnected";
import getWeb3 from "./getWeb3";

import "./App.css";

const App = () => {
  const [totalSupply, setTotalSupply] = useState(0);
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [accountBalance, setAccountBalance] = useState(0);
  const [contract, setContract] = useState(null);
  const [toAddress, setToAddress] = useState('');
  const [toAmount, setToAmount] = useState(0);

  useEffect(() => {
    // initAsync();
  }, [])

  useEffect(() => {
    if (!contract) return;

    try {
      contract.methods.totalSupply().call(function(err,res) {
        if (!err) {
          setTotalSupply(res/(10 ** 18))
        } else {
          console.log(err);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, [contract])

  useEffect(() => {
    if (!account || !contract) return;
    updateBalance();
  }, [account])

  const updateBalance = () => {
    contract.methods.balanceOf(account).call(function(err,res) {
      if (!err) {
        setAccountBalance(parseInt(res, 10));
      } else {
        console.log(err);
      }
    });
  }

  const initAsync = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      const accounts = await web3.eth.getAccounts();

      // Get the contract instance.
      const networkId = await web3.eth.net.getId();

      const deployedNetwork = GSwapContract.networks[networkId];

      const instance = new web3.eth.Contract(
        GSwapContract.abi,
        deployedNetwork && deployedNetwork.address,
        { from: accounts[0] }
      );

      setWeb3(web3);

      setContract(instance);

      setAccount(accounts[0]);
    } catch (error) {
      // Catch any errors for any of the above operations.
      alert(
        `Failed to load web3, accounts, or contract. Check console for details.`,
      );
      console.error(error);
    }
  }

  const transferFunds = async (e) => {
    e.preventDefault();

    if (toAmount <= 0) return window.alert('Cant be 0')

    const strAmt = (toAmount * 10 ** 18).toLocaleString('fullwide', {useGrouping:false} );

    console.log(strAmt)

    try {
      contract.methods.transfer(toAddress, strAmt).send(function(err,res) {
        if (!err) {
          console.log(res)
        } else {
          console.log(err);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  if (!web3) {
    return <Disconnected onConnect={initAsync} />;
  }

  return (
    <div className="App">

      <h1>GSWAP</h1>

      <p>Contract: {contract ? contract._address : 'Loading'}</p>

      <p>
        The illest coin around
      </p>

      <div style={{ paddingBottom: 15 }}>Total coins in circulation is: {totalSupply || '-------'}</div>

      <form onSubmit={transferFunds}>
        <input placeholder="address" value={toAddress} onChange={e => setToAddress(e.target.value)} />
        <br />
        <br />
        <input type="number" value={toAmount} onChange={e => setToAmount(parseInt(e.target.value))} />
        <br />
        <br />
        <button className="btn btn-warning" type="submit">Transfer</button>
      </form>

      <div style={{ paddingTop: 50 }}>
        <h3>Wallets</h3>
        <h5>Eth address: {account}</h5>
        <h5>GSWAP: {accountBalance / (10 ** 18)}</h5>
        <button onClick={updateBalance}>Refresh Balanace</button>
      </div>
    </div>
  );
}

export default App;
